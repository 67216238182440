<template>
  <div
    v-if="price"
    class="flex flex-col text-xs font-bold text-grey md:text-lg"
  >
    <p v-if="sale" class="font-bold text-warning">
      {{ NoDecimalSale }}
    </p>
    <p
      :class="{
        'md:text-grey':
          $features?.product_catalogue.screens?.shop_screen == '3',
        'font-normal line-through md:text-base': sale,
      }"
    >
      {{ NoDecimalPrice }}
    </p>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  price: {
    type: String,
    default: "",
  },
  sale: {
    type: String,
    default: "",
  },
});

const NoDecimalPrice = computed(() => {
  let currencyParts = props.price.split(".0");
  let withoutDecimal = currencyParts[0];
  return withoutDecimal;
});

const NoDecimalSale = computed(() => {
  let currencyParts = props.sale.split(".0");
  let withoutDecimal = currencyParts[0];
  return withoutDecimal;
});
</script>
